/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

"use strict";
"use client";
import Celebrate from "@/components/tasks/Celebrate";
import RequireAuthWrapper from "@/components/auth/RequireAuthWrapper";
import { Tasks } from "@/components/context/TaskContext";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { firebaseApp, storage } from "@/libs/firebase/clientApp";
import { isSupported, logEvent, getAnalytics } from "firebase/analytics";
import { ref, getBlob } from "firebase/storage";
import MarkdownRender from "@/components/tasks/MarkdownRender";

const LEADERBOARD = process.env.NEXT_PUBLIC_LEADERBOARD_URL;

export default function Home() {
  const [intro, setIntro] = useState<string>();
  const { scores, event } = Tasks();

  useEffect(() => {
    async function getContent() {
      if (event && event?.intro) {
        const path = `assets/${event?.intro}`;
        const pr = ref(storage, path);
        const blob = await getBlob(pr);
        const text = (await blob.text())
          .replaceAll(
            "%%LEADERBOARD_URL%%",
            LEADERBOARD || "http://leaderboard",
          )
          .replaceAll("%%AppName%%", event?.name || "hacksday");
        setIntro(text);
      }
    }
    getContent();
  }, [event, event?.intro, event?.name]);

  useEffect(() => {
    if (event?.name) {
      isSupported().then(async (yes) => {
        if (yes) {
          logEvent(getAnalytics(firebaseApp), `score_app`);
        }
      });
      document.title = `${event.name} - Welcome`;
    }
  }, [event?.name]);

  return (
    <RequireAuthWrapper>
      {scores && <Celebrate />}
      <Box sx={{ width: "100%", maxWidth: "lg" }}>
        <MarkdownRender content={intro} />
      </Box>
    </RequireAuthWrapper>
  );
}
